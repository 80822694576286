import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/layout.vue";
import gxGather from "@/views/gxGather/index.vue";
import gxGathers from "@/views/gxGather/school/index.vue";
import abroad from "@/views/gxGather/abroad/index.vue";
import graduate from "@/views/gxGather/graduate/index.vue";

Vue.use(VueRouter);
const routesAll = [
  {
    path: "/VIP/textbookPage",
    name: "教材",
    component: () => import("@/views/class/textbookPage.vue"),
  },
  {
    path: "/VIP/sourcePage",
    name: "资料",
    component: () => import("@/views/class/sourcePage.vue"),
  },
  {
    path: "/marketing/promotionGraphics",
    name: "分享图文页",
    component: () => import("@/views/marketing/promotionGraphics.vue"),
  },
  {
    path: "/marketing/promotionTable",
    name: "分享表单",
    component: () => import("@/views/marketing/promotionTable.vue"),
  },
  {
    path: "/marketing/promotionSuccess",
    name: "分享成功",
    component: () => import("@/views/marketing/promotionSuccess.vue"),
  },
  {
    path: "/marketing/Psychological",
    name: "心理咨询师",
    component: () => import("@/views/marketing/psychological.vue"),
  },
  {
    path: "/OffShelf",
    component: () => import("@/views/error/OffShelf.vue"),
  },
  {
    path: "/newPactivity",
    name: "新人有礼",
    component: () => import("@/views/newPactivity.vue"),
  },
  {
    path: "/agent/certificate",
    name: "资格证",
    component: () => import("@/views/agent/certificate/index.vue"),
  },
  {
    path: "/agent/consulting",
    name: "资格证报名",
    component: () => import("@/views/agent/certificate/consulting.vue"),
  },
  {
    path: "/agent/scroll",
    name: "资格证",
    component: () => import("@/views/agent/certificate/scroll.vue"),
  },
  {
    path: "/gxGathers",
    name: "布局页",
    component: gxGathers,
    redirect: "/gxGathers",
    children: [
      {
        path: "/gxGathers/index",
        name: "学校主页",
        component: () => import("@/views/gxGather/school/zsbIndex.vue"),
      },
      {
        path: "/gxGathers/school/synopsis",
        name: "学校简介",
        component: () => import("@/views/gxGather/school/pages/synopsis.vue"),
      },
      {
        path: "/gxGathers/school/teacher",
        name: "师资团队",
        component: () => import("@/views/gxGather/school/pages/teacher.vue"),
      },
      {
        path: "/gxGathers/school/question",
        name: "常见问题",
        component: () => import("@/views/gxGather/school/pages/question.vue"),
      },
      {
        path: "/gxGathers/school/enrollmentGuideList",
        name: "招生简章",
        component: () => import("@/views/gxGather/school/pages/enrollmentGuideList.vue"),
      },
      {
        path: "/gxGathers/school/enrollmentGuide",
        name: "招生简章",
        component: () => import("@/views/gxGather/school/pages/enrollmentGuide.vue"),
      },
      {
        path: "/gxGathers/school/school",
        name: "校园风采",
        component: () => import("@/views/gxGather/school/pages/school.vue"),
      },
      {
        path: "/gxGathers/school/decs",
        name: "学校新闻",
        component: () => import("@/views/gxGather/school/pages/decs.vue"),
      },
      {
        path: "/gxGathers/school/decsDetail",
        name: "学校新闻",
        component: () => import("@/views/gxGather/school/pages/decsDetail.vue"),
      },
    ]
  },

  {
    path: "/gxGather",
    name: "布局页",
    component: gxGather,
    redirect: "/gxGather",
    children: [
      {
        path: "/gxGather/indexs",
        name: "高校",
        component: () => import("@/views/gxGather/TechnicalCollege/index.vue"),
      },
      {
        path: "/gxGather/selfStudy/index",
        name: "自学考试",
        component: () => import("@/views/gxGather/TechnicalCollege/selfStudy/index.vue"),
      },
      {
        path: "/gxGather/selfStudy/indexs",
        name: "成人考试",
        component: () => import("@/views/gxGather/TechnicalCollege/selfStudy/index.vue"),
      },
      {
        path: "/gxGather/openEdu/index",
        name: "开放教育",
        component: () => import("@/views/gxGather/TechnicalCollege/openEdu/index.vue"),
      },
      {
        path: "/gxGather/majorList",
        name: "专业列表",
        component: () => import("@/views/gxGather/majorList/index.vue"),
      },
      {
        path: "/gxGather/detailIndex",
        name: "专业详情",
        component: () => import("@/views/gxGather/majorList/detailIndex.vue"),
      },
      {
        path: "/gxGather/academy",
        name: "院校列表",
        component: () => import("@/views/gxGather/academy/index.vue"),
      },
      {
        path: "/gxGather/brochures",
        name: "简章列表",
        component: () => import("@/views/gxGather/brochures/index.vue"),
      },
      {
        path: "/gxGather/components/question",
        name: "常见问题",
        component: () => import("@/views/gxGather/components/question.vue"),
      },
      {
        path: "/gxGather/components/news",
        name: "学校新闻",
        component: () => import("@/views/gxGather/components/news.vue"),
      },
      {
        path: "/gxGather/components/newsDetail",
        name: "新闻",
        component: () => import("@/views/gxGather/components/newsDetail.vue"),
      },
      
    ]
  },
  {
    path: "/abroad",
    name: "布局页",
    component: abroad,
    redirect: "/abroad",
    children: [
    
      {
        path: "/gxGather/abroad/indexs",
        name: "高校",
        component: () => import("@/views/gxGather/abroad/pages/index.vue"),
      },
      {
        path: "/gxGather/abroad/abroad",
        name: "留学国家",
        component: () => import("@/views/gxGather/abroad/pages/abroadDetail.vue"),
      },
      {
        path: "/gxGather/abroad/index",
        name: "3+2留学",
        component: () => import("@/views/gxGather/abroad/threeOrTwo/index.vue"),
      },
      {
        path: "/gxGather/abroad/TwoT",
        name: "3+2留学",
        component: () => import("@/views/gxGather/abroad/TwoT/index.vue"),
      },{
        path: "/gxGather/abroad/preparatory",
        name: "留学预科",
        component: () => import("@/views/gxGather/abroad/preparatory/index.vue"),
      },
      // {
      //   path: "/gxGather/selfStudy/indexs",
      //   name: "成人考试",
      //   component: () => import("@/views/gxGather/TechnicalCollege/selfStudy/index.vue"),
      // },
      // {
      //   path: "/gxGather/openEdu/index",
      //   name: "开放教育",
      //   component: () => import("@/views/gxGather/TechnicalCollege/openEdu/index.vue"),
      // },
      // {
      //   path: "/gxGather/majorList",
      //   name: "专业列表",
      //   component: () => import("@/views/gxGather/majorList/index.vue"),
      // },
      // {
      //   path: "/gxGather/detailIndex",
      //   name: "专业详情",
      //   component: () => import("@/views/gxGather/majorList/detailIndex.vue"),
      // },
      {
        path: "/gxGather/abroad/academy",
        name: "院校列表",
        component: () => import("@/views/gxGather/academy/indexabroad.vue"),
      },
      // {
      //   path: "/gxGather/brochures",
      //   name: "简章列表",
      //   component: () => import("@/views/gxGather/brochures/index.vue"),
      // },
      {
        path: "/gxGather/abroad/question",
        name: "常见问题",
        component: () => import("@/views/gxGather/components/question.vue"),
      },
      {
        path: "/gxGather/abroad/news",
        name: "学校新闻",
        component: () => import("@/views/gxGather/components/newsa.vue"),
      },
      {
        path: "/gxGather/abroad/newsDetail",
        name: "新闻",
        component: () => import("@/views/gxGather/components/newsDetail.vue"),
      },
      
    ]
  },
  {
    path: "/graduate",
    name: "布局页",
    component: graduate,
    redirect: "/graduate",
    children: [
    
      {
        path: "/gxGather/graduate/indexs",
        name: "高校",
        component: () => import("@/views/gxGather/graduate/pages/index.vue"),
      },
     
      {
        path: "/gxGather/graduate/index",
        name: "同等学历申硕",
        component: () => import("@/views/gxGather/graduate/threeOrTwo/index.vue"),
      },
      {
        path: "/gxGather/graduate/TwoT",
        name: "中外合作",
        component: () => import("@/views/gxGather/graduate/TwoT/index.vue"),
      },{
        path: "/gxGather/graduate/preparatory",
        name: "高级研修班",
        component: () => import("@/views/gxGather/graduate/preparatory/index.vue"),
      },
      // {
      //   path: "/gxGather/selfStudy/indexs",
      //   name: "成人考试",
      //   component: () => import("@/views/gxGather/TechnicalCollege/selfStudy/index.vue"),
      // },
      // {
      //   path: "/gxGather/openEdu/index",
      //   name: "开放教育",
      //   component: () => import("@/views/gxGather/TechnicalCollege/openEdu/index.vue"),
      // },
      {
        path: "/gxGather/majorListgraduate",
        name: "专业列表",
        component: () => import("@/views/gxGather/majorList/indexgraduate.vue"),
      },
      {
        path: "/gxGather/detailIndexgraduate",
        name: "专业详情",
        component: () => import("@/views/gxGather/majorList/detailIndexgraduate.vue"),
      },
      {
        path: "/gxGather/graduate/academy",
        name: "院校列表",
        component: () => import("@/views/gxGather/academy/indexgraduate.vue"),
      },
      // {
      //   path: "/gxGather/brochures",
      //   name: "简章列表",
      //   component: () => import("@/views/gxGather/brochures/index.vue"),
      // },
      {
        path: "/gxGather/graduate/question",
        name: "常见问题",
        component: () => import("@/views/gxGather/components/question.vue"),
      },
      {
        path: "/gxGather/graduate/news",
        name: "学校新闻",
        component: () => import("@/views/gxGather/components/newsg.vue"),
      },
      {
        path: "/gxGather/graduate/newsDetail",
        name: "新闻",
        component: () => import("@/views/gxGather/components/newsDetail.vue"),
      },
      
    ]
  },
  {
    path: "/gxGather/index",
    name: "高校",
    component: () => import("@/views/gxGather/index.vue"),
  },

];
const routesPC = [
  {
    path: "/",
    name: "布局页",
    component: layout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "首页",
        component: () => import("@/views/home.vue"),
      },
      {
        path: "/search",
        name: "搜索",
        component: () => import("@/views/search.vue"),
      },
      {
        path: "/mall",
        name: "教辅商城",
        component: () => import("@/views/mall/stumall.vue"),
      },
      {
        path: "/mall/material",
        name: "教辅详情",
        component: () => import("@/views/mall/material.vue"),
      },
      {
        path: "/mall/sourcePage",
        name: "立即试读",
        component: () => import("@/views/mall/sourcePage.vue"),
      },
      {
        path: "/mall/orderView",
        name: "确认订单",
        component: () => import("@/views/mall/orderView.vue"),
      },
      {
        path: "/mall/orderCar",
        name: "订单购物车",
        component: () => import("@/views/mall/orderCar.vue"),
      },
      {
        path: "/allcurriculum/typeclass",
        name: "课程分类",
        component: () => import("@/views/class/typeclass.vue"),
      },
      {
        path: "/allcurriculum/typeclasssixth",
        name: "课程分类",
        component: () => import("@/views/class/typeclasssixth.vue"),
      },

      {
        path: "/agent/scroll2",
        name: "课程分类",
        component: () => import("@/components/swipers.vue"),
      },

      {
        // path:"/typeclassxq/:id/:type/:entrantsPrice/:jgPrice",
        path: "/jingpin/courer",
        name: "精品课程",
        component: () => import("@/views/class/courer.vue"),
      },
      {
        // path:"/typeclassxq/:id/:type/:entrantsPrice/:jgPrice",
        path: "/jingpin/courersixth",
        name: "精品课程",
        component: () => import("@/views/class/courersixth.vue"),
      },
      {
        path: "/VIP/VIPClass",
        name: "VIP专属班",
        component: () => import("@/views/class/VIPclass.vue"),
      },
      {
        path: "/VIP/VIPClasssixth",
        name: "VIP专属班",
        component: () => import("@/views/class/VIPclasssixth.vue"),
      },
      {
        path: "/VIP/VIPClassxq",
        name: "VIP专属班详情",
        component: () => import("@/views/class/VIPclassxq.vue"),
      },
      {
        path: "/VIP/VIPclassxqPartner",
        name: "VIP专属班详情",
        component: () => import("@/views/class/VIPclassxqPartner.vue"),
      },
      {
        path: "/VIP/oneToOneClass",
        name: "1v1直播课详情",
        component: () => import("@/views/class/oneToOneClass.vue"),
      },
      {
        path: "/oneToOneLive",
        name: "1v1直播课播放",
        component: () => import("@/views/live/oneToOneLive.vue"),
      },
      {
        path: "/VIP/textbookPage",
        name: "教材",
        component: () => import("@/views/class/textbookPage.vue"),
      },
      /* 公开课（新） */
      {
        path: "/publicClass",
        name: "公开课",
        component: () => import("@/views/publicClass/index.vue"),
      },
      {
        path: "/publicClasssixth",
        name: "公开课",
        component: () => import("@/views/publicClass/indexsixth.vue"),
      },
      {
        path: "/publicClass/publicClassDetail",
        name: "公开课详情",
        component: () => import("@/views/publicClass/publicClassDetail.vue"),
      },
      {
        path: "/faculty",
        name: "师资力量",
        component: () => import("@/views/faculty/faculty.vue"),
      },
      {
        path: "/facultyPartner",
        name: "师资力量",
        component: () => import("@/views/faculty/facultyPartner.vue"),
      },
      {
        path: "/facultysixth",
        name: "师资力量",
        component: () => import("@/views/faculty/facultysixth.vue"),
      },
      {
        path: "/faculty/teacherDetail",
        name: "老师详情",
        component: () => import("@/views/faculty/teacherDetail.vue"),
      },
      {
        path: "/faculty/teacherDetailPartner",
        name: "老师详情",
        component: () => import("@/views/faculty/teacherDetailPartner.vue"),
      },
      {
        // path:"/typeclassxq/:id/:type/:entrantsPrice/:jgPrice",
        path: "/typeclassxq",
        name: "知识套餐课详情",
        component: () => import("@/views/class/typeclassxq.vue"),
      },
      {
        // path:"/typeclassxq/:id/:type/:entrantsPrice/:jgPrice",
        path: "/typeclassxqpartner",
        name: "知识套餐课详情(合伙)",
        component: () => import("@/views/class/typeclassxqpartner.vue"),
      },
      {
        path: "/typeclassxq/textbookDetails",
        name: "教材详情",
        component: () => import("@/views/class/typeclassxq/textbookDetails"),
      },
      {
        path: "/zhiboclass",
        name: "直播课",
        component: () => import("@/views/live/zhiboclass.vue"),
      },
      {
        path: "/camp",
        name: "训练营",
        component: () => import("@/views/camp/campClass.vue"),
      },
      {
        path: "/campDetail",
        name: "训练营详情",
        component: () => import("@/views/camp/campDetail.vue"),
      },
      {
        path: "/zhiboclasssixth",
        name: "直播课",
        component: () => import("@/views/live/zhiboclasssixth.vue"),
      },
      {
        path: "/zhiboclassxqPartner",
        name: "直播课",
        component: () => import("@/views/live/zhiboclassxqPartner.vue"),
      },
      {
        path: "/zhiboclassxq",
        name: "直播课详情",
        component: () => import("@/views/live/zhiboclassxq.vue"),
      },
      {
        path: "/livebofang",
        name: "直播课播放",
        component: () => import("@/views/live/livebofang.vue"),
      },
      {
        path: "/livebofangxbk",
        name: "直播课播放",
        component: () => import("@/views/live/livebofangxbk.vue"),
      },
      {
        path: "/videoRoomxbk",
        name: "直播课播放",
        component: () => import("@/components/Know/videoRoomxbk.vue"),
      },
      {
        path: "/videoxq",
        name: "录播课详情",
        component: () => import("@/views/video/videoxq.vue"),
      },
      {
        path: "/videoxqPartner",
        name: "录播课详情",
        component: () => import("@/views/video/videoxqPartner.vue"),
      },
      {
        path: "/dianbofang",
        name: "知识套餐录播课播放",
        component: () => import("@/views/video/dianbofang.vue"),
      },
      {
        path: "/dianbofangs",
        name: "知识套餐录播课播放",
        component: () => import("@/views/video/dianbofangnew.vue"),
      },
      {
        path: "/openclass",
        name: "公开课",
        component: () => import("@/views/open/openclass.vue"),
      },
      {
        path: "/openclassxq",
        name: "公开课详情",
        component: () => import("@/views/open/openclassxq.vue"),
      },
      {
        path: "/openclassbofang",
        name: "公开课播放",
        component: () => import("@/views/open/openclassbofang.vue"),
      },
      {
        path: "/seventhPage/myOperationDetails",
        name: "我的作业详情",
        component: () =>
          import("@/views/seventhPage/mine/learn/myOperationDetails.vue"),
      },
      {
        path: "/allnews",
        name: "全部新闻资讯",
        component: () => import("@/views/news/allnews.vue"),
      },
      {
        path: "/newsxq/:id",
        name: "新闻资讯详情",
        component: () => import("@/views/news/newsxq.vue"),
      },
      {
        path: "/zgznewsxq",
        name: "新闻资讯详情",
        component: () => import("@/views/news/zgznewsxq.vue"),
      },
      {
        path: "/partnerNewsxq/:id",
        name: "高校新闻资讯详情",
        component: () => import("@/views/news/partnerNewsxq.vue"),
      },
      {
        path: "/seventhPage/shiyong",
        name: "实用信息详情",
        component: () => import("@/views/seventhPage/shiyong.vue"),
      },
      {
        path: "/data/dataPackage",
        name: "学习资料",
        component: () => import("@/views/data/dataPackage.vue"),
      },
      {
        path: "/data/dataPackagesixth",
        name: "学习资料",
        component: () => import("@/views/data/dataPackagesixth.vue"),
      },
      {
        path: "/data/materialPage",
        name: "资料列表",
        component: () => import("@/views/data/materialPage.vue"),
      },
      {
        path: "/data/materialPagePartner",
        name: "合伙共建资料列表",
        component: () => import("@/views/data/materialPagePartner.vue"),
      },
      {
        path: "/data/dataPackageDetail",
        name: "查看资料",
        component: () => import("@/views/data/dataPackageDetail.vue"),
      },
      {
        path: "/data/dataPackageDetailXbk",
        name: "查看资料",
        component: () => import("@/views/data/dataPackageDetailXbk.vue"),
      },
      {
        path: "/data/dataPackageDetails",
        name: "查看资料",
        component: () => import("@/views/data/dataPackageDetails.vue"),
      },
      {
        path: "/projectChannelPage",
        name: "项目频道页",
        component: () =>
          import("@/components/projectChannelPage/projectChannelPage.vue"),
      },
      {
        path: "/ccLivexbk",
        name: "小班课",
        component: () => import("@/components/Know/ccLivexbk.vue"),
      },
      {
        path: "/projectChannelPage/menuContent",
        name: "报考信息",
        component: () =>
          import("@/components/projectChannelPage/components/menuContent.vue"),
      },

      {
        path: "/marketing/activityThemePage",
        name: "活动专题页",
        component: () => import("@/views/marketing/activityThemePage.vue"),
      },
      {
        path: "/marketing/activeEleven",
        name: "双十一活动",
        component: () => import("@/views/marketing/activeEleven.vue"),
      },
      {
        path: "/marketing/activeTwelve",
        name: "双十二活动",
        component: () => import("@/views/marketing/activeTwelve.vue"),
      },
      
      {
        path: "/marketing/militarCivilianPersonnel",
        name: "军队文职",
        component: () =>
          import("@/views/marketing/militarCivilianPersonnel.vue"),
      },
      {
        path: "/marketing/activityThemePage",
        name: "活动专题页",
        component: () => import("@/views/marketing/activityThemePage.vue"),
      },

      {
        path: "/all",
        name: "我的课程学习记录考试",
        component: () => import("@/views/mine/all.vue"),
        children: [
          {
            path: "/all/mycurriculum",
            name: "我的课程",
            component: () => import("@/views/mine/mycurriculum.vue"),
          },
          {
            path: "/all/curriculumName",
            name: "学习中心课程名",
            component: () => import("@/views/mine/curriculumName.vue"),
          },
          {
            path: "/all/myOperation",
            name: "我的作业",
            component: () => import("@/views/mine/myOperation.vue"),
          },
          {
            path: "/all/courseLearningRatexbk",
            name: "学习中心课程详情",
            component: () => import("@/views/mine/courseLearningRatexbk.vue"),
          },
          {
            path: "/all/courseLearningRate",
            name: "学习中心课程详情",
            component: () => import("@/views/mine/courseLearningRate.vue"),
          },
          {
            path: "/all/mycurriculumsixth",
            name: "我的课程",
            component: () => import("@/views/mine/mycurriculumsixth.vue"),
          },
          {
            path: "/all/study",
            name: "学习记录",
            component: () => import("@/views/mine/study.vue"),
          },
          {
            path: "/all/record",
            name: "学习记录",
            component: () => import("@/views/mine/record.vue"),
          },
          {
            path: "/all/myexamination",
            name: "我的题库",
            component: () => import("@/views/mine/myexamination.vue"),
          },
          {
            path: "/all/examinationRecord",
            name: "我的题库练习记录",
            component: () => import("@/views/mine/examinationRecord.vue"),
          },
          {
            path: "/all/mydata",
            name: "我的资料",
            component: () => import("@/views/data/mineData.vue"),
          },
          {
            path: "/all/myRoom",
            name: "我的考场",
            component: () => import("@/views/mine/myRoom.vue"),
          },
          {
            path: "/all/myTask",
            name: "我的作业",
            component: () => import("@/views/quesTion/myTask.vue"),
          },
        ],
      },
      {
        path: "/allsixth",
        name: "我的课程学习记录考试",
        component: () => import("@/views/mine/allsixth.vue"),
        children: [
          {
            path: "/allsixth/mycurriculumsixth",
            name: "我的课程",
            component: () => import("@/views/mine/mycurriculumsixth.vue"),
          },
          {
            path: "/all/curriculumNamesixth",
            name: "学习中心课程名",
            component: () => import("@/views/mine/curriculumNamesixth.vue"),
          },
          {
            path: "/all/courseLearningRatesixthxbk",
            name: "学习中心课程详情",
            component: () =>
              import("@/views/mine/courseLearningRatesixthxbk.vue"),
          },
          {
            path: "/all/courseLearningRatesixth",
            name: "学习中心课程详情",
            component: () => import("@/views/mine/courseLearningRatesixth.vue"),
          },
          // {
          //   path: "/allsixth/mycurriculum",
          //   name: "我的课程",
          //   component: () => import("@/views/mine/mycurriculumsixth.vue"),
          // },
          {
            path: "/allsixth/study",
            name: "最近学习",
            component: () => import("@/views/mine/studysixth.vue"),
          },
          {
            path: "/allsixth/myexamination",
            name: "我的题库",
            component: () => import("@/views/mine/myexaminationsixth.vue"),
          },
          {
            path: "/allsixth/mydata",
            name: "我的资料",
            component: () => import("@/views/data/mineDatasixth.vue"),
          },
          {
            path: "/allsixth/myOperation",
            name: "我的作业",
            component: () => import("@/views/mine/myOperationsixth.vue"),
          },
          {
            path: "/allsixth/myexamination",
            name: "我的考试",
            component: () => import("@/views/mine/myexaminationsixth.vue"),
          },
          {
            path: "/allsixth/myRoom",
            name: "我的考场",
            component: () => import("@/views/mine/myRoomsixth.vue"),
          },
          {
            path: "/allsixth/myTask",
            name: "我的作业",
            component: () => import("@/views/quesTion/myTask.vue"),
          },
          // {
          //   path: '/allsixth/myTask',
          //   name: '我的作业',
          //   component: () => import('@/views/quesTion/myTask.vue')
          // },
          {
            path: "/allsixth/learningRecord",
            name: "学习记录",
            component: () => import("@/views/sixthPage/record.vue"),
          },
        ],
      },
      // 招商加盟个人中心
      {
        path: "/myView",
        name: "个人中心",
        component: () => import("@/views/mine/myView.vue"),
        children: [
          {
            path: "/my/Timetable",
            name: "个人中心",
            component: () => import("@/views/mine/my/Timetable.vue"),
          },
          {
            path: "/my/myInfo",
            name: "个人资料",
            component: () => import("@/views/mine/my/myInfo.vue"),
          },
          {
            path: "/my/myInfo",
            name: "个人资料",
            component: () => import("@/views/mine/my/myInfo.vue"),
          },
          {
            path: "/my/mineOrder",
            name: "我的订单",
            component: () => import("@/views/mine/my/mineOrder.vue"),
          },
          {
            path: "/my/mineCoupon",
            name: "我的优惠券",
            component: () => import("@/views/mine/my/mineCoupon.vue"),
          },
          {
            path: "/my/mineExchange",
            name: "我的兑换券",
            component: () => import("@/views/mine/my/mineExchange.vue"),
          },
          {
            path: "/my/myCard",
            name: "学习卡",
            component: () => import("@/views/mine/my/myCard.vue"),
          },
          {
            path: "/my/myAskQuestions",
            name: "我的提问",
            component: () => import("@/views/mine/my/myAskQuestions.vue"),
          },
          {
            path: "/my/feedback",
            name: "意见反馈",
            component: () => import("@/views/mine/my/feedback.vue"),
          },
          {
            path: "/my/accountSetting",
            name: "账户设置",
            component: () => import("@/views/mine/my/accountSetting.vue"),
          },
          {
            path: "/my/mineNews",
            name: "消息通知",
            component: () => import("@/views/mine/my/mineNews.vue"),
          },
          {
            path: "/my/collect",
            name: "我的收藏",
            component: () => import("@/views/mine/my/collect.vue"),
          },
          {
            path: "/myAchievement",
            name: "我的成就",
            component: () => import("@/views/camp/achievement.vue"),
          },
        ],
      },
      // 大客户个人中心
      {
        path: "/myViewsixth",
        name: "个人中心",
        component: () => import("@/views/mine/myViewsixth.vue"),
        children: [
          {
            path: "/mysixth/Timetable",
            name: "个人中心",
            component: () => import("@/views/mine/my/Timetablesixth.vue"),
          },
          {
            path: "/mysixth/myInfo",
            name: "个人资料",
            component: () => import("@/views/mine/my/myInfosixth.vue"),
          },
          {
            path: "/mysixth/mineOrder",
            name: "我的订单",
            component: () => import("@/views/mine/my/mineOrdersixth.vue"),
          },
          {
            path: "/mysixth/mineCoupon",
            name: "我的优惠券",
            component: () => import("@/views/mine/my/mineCouponsixth.vue"),
          },
          {
            path: "/mysixth/mineExchange",
            name: "我的兑换券",
            component: () => import("@/views/mine/my/mineExchangesixth.vue"),
          },
          {
            path: "/mysixth/myCard",
            name: "学习卡",
            component: () => import("@/views/mine/my/myCardsixth.vue"),
          },
          {
            path: "/mysixth/feedback",
            name: "意见反馈",
            component: () => import("@/views/mine/my/feedbacksixth.vue"),
          },
          {
            path: "/mysixth/accountSetting",
            name: "账户设置",
            component: () => import("@/views/mine/my/accountSettingsixth.vue"),
          },
          {
            path: "/mysixth/mineNews",
            name: "消息通知",
            component: () => import("@/views/mine/my/mineNewssixth.vue"),
          },
          {
            path: "/mysixth/collect",
            name: "我的收藏",
            component: () => import("@/views/mine/my/collectsixth.vue"),
          },
        ],
      },
      {
        path: "/orders/classOrder",
        name: "知识套餐购买",
        component: () => import("@/views/order/classOrder.vue"),
      },
      {
        path: "/orders/classPartnerOrder",
        name: "高校知识套餐购买",
        component: () => import("@/views/order/classPartnerOrder.vue"),
      },
      {
        path: "/orders/classSure",
        /* "/orders/classSure/:ids/:class", */
        name: "组合购买",
        component: () => import("@/views/order/classSure.vue"),
      },
      {
        path: "/orders/:orderNumber",
        name: "个人中心订单页",
        component: () => import("@/views/order/orders.vue"),
      },
      {
        path: "/partnerOrders/:orderNumber",
        name: "高校个人中心订单页",
        component: () => import("@/views/order/partnerOrders.vue"),
      },

      {
        path: "/paySucces",
        name: "支付成功",
        component: () => import("@/views/order/paySucces.vue"),
      },
      /* 支付宝支付 */
      {
        path: "/payment",
        name: "支付页",
        component: () => import("@/views/order/payment.vue"),
      },
      /* 微信支付 */
      {
        path: "/wxPayment",
        name: "支付页",
        component: () => import("@/views/order/wxPayment.vue"),
      },
      {
        path: "/login",
        name: "登录页",
        component: () => import("@/views/login/newLogin.vue"),
      },
      {
        path: "/agreement",
        name: "注册协议",
        component: () => import("@/views/login/agreement.vue"),
      },
      {
        path: "/forget",
        name: "忘记密码",
        component: () => import("@/views/login/forget.vue"),
      },
      {
        path: "/register",
        name: "注册",
        component: () => import("@/views/login/register.vue"),
      },
    ],
  },
  {
    path: "/quesTion",
    component: layout,
    redirect: "/quesTion",
    meta: {
      title: "题库",
      icon: "iconfont iconbuke",
      alwaysShow: true, // will always show the root menu
    },
    children: [
      {
        path: "/quesTion/quesTionList",
        name: "题库",
        component: () => import("@/views/quesTion/quesTionList.vue"),
      },
      {
        path: "/quesTion/quesTionListsixth",
        name: "题库",
        component: () => import("@/views/quesTion/quesTionListsixth.vue"),
      },
      {
        path: "/quesTion/examRoom/index",
        name: "虚拟考场",
        component: () => import("@/views/quesTion/examRoom/index.vue"),
      },
      {
        path: "/quesTion/examRoom/examRoomDetail",
        name: "虚拟考场",
        component: () => import("@/views/quesTion/examRoom/examRoomDetail.vue"),
      },
      {
        path: "/quesTion/examRandom",
        name: "随机模式做题",
        component: () => import("@/views/quesTion/examRandom.vue"),
      },
      {
        path: "/quesTion/examTest",
        name: "练习模式做题",
        component: () => import("@/views/quesTion/examTest.vue"),
      },
      {
        path: "/quesTion/examDetails",
        name: "做题",
        component: () => import("@/views/quesTion/examDetails.vue"),
      },
      {
        path: "/quesTion/examDetailpartner",
        name: "做题",
        component: () => import("@/views/quesTion/examDetailpartner.vue"),
      },
      {
        path: "/quesTion/examDetailss",
        name: "做题",
        component: () => import("@/views/quesTion/examDetailss.vue"),
      },
      {
        path: "/all/examPlace/answer",
        name: "考点做题",
        component: () => import("@/views/quesTion/examPlace/answer.vue"),
      },
      {
        path: "/all/examPack",
        name: "试卷包详情",
        component: () => import("@/views/quesTion/examPack.vue"),
      },
      {
        path: "/all/examPackPartner",
        name: "试卷包详情",
        component: () => import("@/views/quesTion/examPackPartner.vue"),
      },
    ],
  },
  {
    path: "/knowle",
    component: layout,
    redirect: "/knowle",
    meta: {
      title: "知识点",
      icon: "iconfont iconbuke",
      alwaysShow: true, // will always show the root menu
    },
    children: [
      {
        path: "/imgCouser/index",
        name: "图文课程",
        component: () => import("@/views/know/imgCouser/index.vue"),
      },
      {
        path: "/imgCouser/imgChatView",
        name: "图文章节",
        component: () => import("@/views/know/imgCouser/imgChatView.vue"),
      },
      {
        path: "/faceCouser/index",
        name: "面授课程",
        component: () => import("@/views/know/faceCouser/index.vue"),
      },
      {
        path: "/faceCouser/faceChatView",
        name: "面授章节",
        component: () => import("@/views/know/faceCouser/faceChatView.vue"),
      },
      {
        path: "/audioCouser/index",
        name: "音频课程",
        component: () => import("@/views/know/audioCouser/index.vue"),
      },
      {
        path: "/audioCouser/audioChatView",
        name: "音频章节",
        component: () => import("@/views/know/audioCouser/audioChatView.vue"),
      },
    ],
  },
  /* 高校合伙人 */
  {
    path: "/seventhPage",
    name: "高校合伙人",
    component: layout,
    redirect: "/seventhPage",
    children: [
      {
        path: "/seventhPage/typeclass",
        name: "课程分类",
        component: () => import("@/views/seventhPage/typeclass.vue"),
      },
      {
        path: "/seventhPage/courer",
        name: "精品课程",
        component: () => import("@/views/seventhPage/courer.vue"),
      },
      {
        path: "/seventhPage/VIPClass",
        name: "VIP专属班",
        component: () => import("@/views/seventhPage/VIPclass.vue"),
      },
      {
        path: "/seventhPage/publicClass",
        name: "公开课",
        component: () => import("@/views/seventhPage/publicClass.vue"),
      },
      {
        path: "/seventhPage/zhiboclass",
        name: "直播课",
        component: () => import("@/views/seventhPage/zhiboclass.vue"),
      },
      {
        path: "/seventhPage/dataPackage",
        name: "学习资料",
        component: () => import("@/views/seventhPage/dataPackage.vue"),
      },
      {
        path: "/seventhPage/quesTionList",
        name: "题库",
        component: () => import("@/views/seventhPage/quesTionList.vue"),
      },
      {
        path: "/seventhPage/leran",
        name: "我的课程学习记录考试",
        component: () => import("@/views/seventhPage/mine/all.vue"),
        children: [
          {
            path: "/seventhPage/mycurriculum",
            name: "我的课程",
            component: () =>
              import("@/views/seventhPage/mine/learn/mycurriculum.vue"),
          },
          {
            path: "/seventhPage/curriculumName",
            name: "学习中心课程名",
            component: () =>
              import("@/views/seventhPage/mine/learn/curriculumName.vue"),
          },
          {
            path: "/seventhPage/courseLearningRate",
            name: "学习中心课程详情",
            component: () =>
              import("@/views/seventhPage/mine/learn/courseLearningRate.vue"),
          },
          {
            path: "/seventhPage/courseLearningRateXbk",
            name: "学习中心课程详情",
            component: () =>
              import(
                "@/views/seventhPage/mine/learn/courseLearningRateXbk.vue"
              ),
          },
          {
            path: "/seventhPage/myexamination",
            name: "我的题库",
            component: () =>
              import("@/views/seventhPage/mine/learn/myexamination.vue"),
          },
          {
            path: "/seventhPage/mydata",
            name: "我的资料",
            component: () =>
              import("@/views/seventhPage/mine/learn/mineData.vue"),
          },
          {
            path: "/seventhPage/myRoom",
            name: "我的考场",
            component: () =>
              import("@/views/seventhPage/mine/learn/myRoom.vue"),
          },
          {
            path: "/seventhPage/myOperation",
            name: "我的作业",
            component: () =>
              import("@/views/seventhPage/mine/learn/myOperation.vue"),
          },
          {
            path: "/seventhPage/myTask",
            name: "我的作业",
            component: () =>
              import("@/views/seventhPage/mine/learn/myTask.vue"),
          },
          {
            path: "/seventhPage/myTask",
            name: "我的作业",
            component: () =>
              import("@/views/seventhPage/mine/learn/myTask.vue"),
          },
          {
            path: "/seventhPage/study",
            name: "最近学习",
            component: () => import("@/views/seventhPage/mine/learn/study.vue"),
          },
          {
            path: "/seventhPage/learningRecord",
            name: "学习记录",
            component: () =>
              import("@/views/seventhPage/mine/learn/record.vue"),
          },
        ],
      },

      // 聚学U
      {
        path: "/seventhPage/myView",
        name: "个人中心",
        component: () => import("@/views/seventhPage/mine/myView.vue"),
        children: [
          {
            path: "/seventhPage/my/myInfo",
            name: "个人资料",
            component: () =>
              import("@/views/seventhPage/mine/myPage/myInfo.vue"),
          },
          {
            path: "/seventhPage/my/mineOrder",
            name: "我的订单",
            component: () =>
              import("@/views/seventhPage/mine/myPage/mineOrder.vue"),
          },
          {
            path: "/seventhPage/my/mineCoupon",
            name: "我的优惠券",
            component: () =>
              import("@/views/seventhPage/mine/myPage/mineCoupon.vue"),
          },
          {
            path: "/seventhPage/my/mineExchange",
            name: "我的兑换券",
            component: () =>
              import("@/views/seventhPage/mine/myPage/mineExchange.vue"),
          },
          {
            path: "/seventhPage/my/myCard",
            name: "学习卡",
            component: () =>
              import("@/views/seventhPage/mine/myPage/myCard.vue"),
          },
          {
            path: "/seventhPage/my/feedback",
            name: "意见反馈",
            component: () =>
              import("@/views/seventhPage/mine/myPage/feedback.vue"),
          },
          {
            path: "/seventhPage/my/accountSetting",
            name: "账户设置",
            component: () =>
              import("@/views/seventhPage/mine/myPage/accountSetting.vue"),
          },
          {
            path: "/seventhPage/my/mineNews",
            name: "消息通知",
            component: () =>
              import("@/views/seventhPage/mine/myPage/mineNews.vue"),
          },
          {
            path: "/seventhPage/my/collect",
            name: "我的收藏",
            component: () =>
              import("@/views/seventhPage/mine/myPage/collect.vue"),
          },
        ],
      },
    ],
  },
  // 高校共建
  {
    path: "/partnerPage",
    name: "高校合伙人",
    component: layout,
    redirect: "/partnerPage",
    children: [
      {
        path: "/partnerPage/typeclass",
        name: "课程分类",
        component: () => import("@/views/partnerPage/typeclass.vue"),
      },
      {
        path: "/partnerPage/courer",
        name: "精品课程",
        component: () => import("@/views/partnerPage/courer.vue"),
      },
      {
        path: "/partnerPage/VIPClass",
        name: "VIP专属班",
        component: () => import("@/views/partnerPage/VIPclass.vue"),
      },
      {
        path: "/partnerPage/publicClass",
        name: "公开课",
        component: () => import("@/views/partnerPage/publicClass.vue"),
      },
      {
        path: "/partnerPage/zhiboclass",
        name: "直播课",
        component: () => import("@/views/partnerPage/zhiboclass.vue"),
      },
      {
        path: "/partnerPage/dataPackage",
        name: "学习资料",
        component: () => import("@/views/partnerPage/dataPackage.vue"),
      },
      {
        path: "/partnerPage/quesTionList",
        name: "题库",
        component: () => import("@/views/partnerPage/quesTionList.vue"),
      },
      {
        path: "/partnerPage/leran",
        name: "我的课程学习记录考试",
        component: () => import("@/views/partnerPage/mine/all.vue"),
        children: [
          {
            path: "/partnerPage/mycurriculum",
            name: "我的课程",
            component: () =>
              import("@/views/partnerPage/mine/learn/mycurriculum.vue"),
          },
          {
            path: "/partnerPage/curriculumName",
            name: "学习中心课程名",
            component: () =>
              import("@/views/partnerPage/mine/learn/curriculumName.vue"),
          },
          {
            path: "/partnerPage/courseLearningRate",
            name: "学习中心课程详情",
            component: () =>
              import("@/views/partnerPage/mine/learn/courseLearningRate.vue"),
          },
          {
            path: "/partnerPage/myexamination",
            name: "我的题库",
            component: () =>
              import("@/views/partnerPage/mine/learn/myexamination.vue"),
          },
          {
            path: "/partnerPage/coursesDetails",
            name: "我的精品课程详情",
            component: () =>
              import("@/views/partnerPage/mine/learn/coursesDetails.vue"),
          },
          {
            path: "/partnerPage/mydata",
            name: "我的资料",
            component: () =>
              import("@/views/partnerPage/mine/learn/mineData.vue"),
          },
          {
            path: "/partnerPage/myRoom",
            name: "我的考场",
            component: () =>
              import("@/views/partnerPage/mine/learn/myRoom.vue"),
          },
          {
            path: "/partnerPage/myOperation",
            name: "我的作业",
            component: () =>
              import("@/views/partnerPage/mine/learn/myOperation.vue"),
          },
          {
            path: "/partnerPage/myOperationDetails",
            name: "共建模块我的作业详情",
            component: () =>
              import("@/views/partnerPage/mine/learn/myOperationDetails.vue"),
          },
          {
            path: "/partnerPage/recentLive",
            name: "共建模块最近直播",
            component: () =>
              import("@/views/partnerPage/mine/learn/recentLive.vue"),
          },
          {
            path: "/partnerPage/myTask",
            name: "我的作业",
            component: () =>
              import("@/views/partnerPage/mine/learn/myTask.vue"),
          },
          {
            path: "/partnerPage/study",
            name: "最近学习",
            component: () => import("@/views/partnerPage/mine/learn/study.vue"),
          },
          {
            path: "/partnerPage/learningRecord",
            name: "学习记录",
            component: () =>
              import("@/views/partnerPage/mine/learn/record.vue"),
          },
          {
            path: "/partnerPage/myWrongQuestion",
            name: "我的错题",
            component: () =>
              import("@/views/partnerPage/mine/learn/myWrongQuestion.vue"),
          },
          {
            path: "/partnerPage/problemSolving",
            name: "做题记录",
            component: () =>
              import("@/views/partnerPage/mine/learn/problemSolving.vue"),
          },
        ],
      },
      {
        path: "/partnerPage/analyzeQuestion",
        name: "全部解析",
        component: () =>
          import("@/views/partnerPage/mine/learn/analyzeQuestion.vue"),
      },
      {
        path: "/partnerPage/report",
        name: "做题报告",
        component: () => import("@/views/partnerPage/mine/learn/report.vue"),
      },
      {
        path: "/partnerPage/analysis",
        name: "错题解析",
        component: () => import("@/views/partnerPage/mine/learn/analysis.vue"),
      },
      {
        path: "/partnerPage/myView",
        name: "个人中心",
        component: () => import("@/views/partnerPage/mine/myView.vue"),
        children: [
          {
            path: "/partnerPage/my/myInfo",
            name: "个人资料",
            component: () =>
              import("@/views/partnerPage/mine/myPage/myInfo.vue"),
          },
          {
            path: "/partnerPage/my/mineOrder",
            name: "我的订单",
            component: () =>
              import("@/views/partnerPage/mine/myPage/mineOrder.vue"),
          },
          {
            path: "/partnerPage/my/mineCoupon",
            name: "我的优惠券",
            component: () =>
              import("@/views/partnerPage/mine/myPage/mineCoupon.vue"),
          },
          {
            path: "/partnerPage/my/mineExchange",
            name: "我的兑换券",
            component: () =>
              import("@/views/partnerPage/mine/myPage/mineExchange.vue"),
          },
          {
            path: "/partnerPage/my/myAskQuestions",
            name: "我的提问",
            component: () =>
              import("@/views/partnerPage/mine/myPage/myAskQuestions.vue"),
          },
          {
            path: "/partnerPage/my/myCard",
            name: "学习卡",
            component: () =>
              import("@/views/partnerPage/mine/myPage/myCard.vue"),
          },
          {
            path: "/partnerPage/my/feedback",
            name: "意见反馈",
            component: () =>
              import("@/views/partnerPage/mine/myPage/feedback.vue"),
          },
          {
            path: "/partnerPage/my/accountSetting",
            name: "账户设置",
            component: () =>
              import("@/views/partnerPage/mine/myPage/accountSetting.vue"),
          },
          {
            path: "/partnerPage/my/mineNews",
            name: "消息通知",
            component: () =>
              import("@/views/partnerPage/mine/myPage/mineNews.vue"),
          },
          {
            path: "/partnerPage/my/collect",
            name: "我的收藏",
            component: () =>
              import("@/views/partnerPage/mine/myPage/collect.vue"),
          },
        ],
      },
    ],
  },
  /* 营销训练营 */
  {
    path: "/markcamp",
    name: "营销训练营",
    component: layout,
    redirect: "/markcamp",
    children: [
      {
        path: "/markcamp/index",
        name: "营销训练营",
        component: () => import("@/views/markcamp/index.vue"),
      },
      {
        path: "/markcamp/zbclass",
        name: "直播课",
        component: () => import("@/views/markcamp/zbclass.vue"),
      },
      {
        path: "/markcamp/camplist",
        name: "营销训练营列表",
        component: () => import("@/views/markcamp/camplist.vue"),
      },
      {
        path: "/markcamp/tarn",
        name: "营销训练营中转",
        component: () => import("@/views/markcamp/tarn.vue"),
      },
    ],
  },
  /* 聚学U */
  {
    path: "/juXueUView",
    name: "聚学U",
    component: layout,
    redirect: "/juXueUView",
    children: [
      {
        path: "/juXueUView/teacher",
        name: "口碑师资",
        component: () => import("@/views/seventhPage/juXueUView/teacher.vue"),
      },
      {
        path: "/juXueUView/teaching",
        name: "教学模式",
        component: () => import("@/views/seventhPage/juXueUView/teaching.vue"),
      },
      {
        path: "/juXueUView/classroom",
        name: "智慧课堂",
        component: () => import("@/views/seventhPage/juXueUView/classroom.vue"),
      },
      {
        path: "/juXueUView/service",
        name: "深度服务",
        component: () => import("@/views/seventhPage/juXueUView/service.vue"),
      },
      {
        path: "/juXueUView/training",
        name: "人才培养",
        component: () => import("@/views/seventhPage/juXueUView/training.vue"),
      },
    ],
  },
];

const routes = [...routesAll, ...routesPC];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes,
});

export default router;
